<template>
  <div style="width: 100%; height: 100%;background: #fff;"   v-loading="loading"> 
    <el-form :inline="true"  :model="recordsForm" ref="recordsForm" class="recordsForm" >
      <div class="balance" style="color:#409EFF"><p>通用短信余额:{{ SMSbalance }}条</p></div>
      <div class="create_button">
        <el-button type="primary" v-preventReClick icon="el-icon-plus" @click="creatTask()" >新建发送任务</el-button>
      </div>
      <div class="themeCss">
        <el-form-item label="短信主题" prop="sms_theme">
          <el-input  v-model="recordsForm.sms_theme" @keyup.enter.native="submitSearch" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="发送状态" prop="sms_send_status">
          <el-select clearable filterable v-model="recordsForm.sms_send_status" placeholder="请选择">
            <el-option value="发送中" label="发送中"/>
            <el-option value="等待中" label="等待中"/>
            <el-option value="发送完成" label="发送完成"/>
            <el-option value="发送异常" label="发送异常"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search"  type="primary" v-preventReClick @click="submitSearch()">查询</el-button>
          <el-button icon="el-icon-refresh" v-preventReClick @click="resetForm('recordsForm')">重置</el-button>
        </el-form-item>
      </div>
      <el-divider></el-divider>
      <el-table 
        :data="records" 
        row-key="id" 
        :header-cell-style="headClass" 
        id="table" 
        v-loading="pictLoading" 
        element-loading-background="rgb(255,255,255))"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading">
        <el-table-column prop="number" label="任务编号"  align="center"  min-width="50"></el-table-column>
        <el-table-column prop="sms_theme" label="短信主题"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.execution_time ==''">{{scope.row.sms_theme ? scope.row.sms_theme : '--'}}</span>
            <el-popover placement="top-start" 
              title="该定时发送任务执行的时间"
              width="200" 
              trigger="hover">
              <slot><i class="el-icon-time" style="color:#409EFF;font-size:14px;font-weight:600"></i>:{{ scope.row.execution_time }}</slot>
              <span v-show="scope.row.execution_time"  slot="reference"><i class="el-icon-time" style="color:#409EFF;font-size:14px;font-weight:600"></i>{{scope.row.sms_theme}}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="date_time" label="任务创建时间" min-width="100" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="sms_send_status" label="发送状态"  align="center" class="statusFont">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.sms_send_status === '发送中'">{{scope.row.sms_send_status}}</el-tag>
            <el-tag  v-if="scope.row.sms_send_status === '等待中'">{{scope.row.sms_send_status}}</el-tag>
            <el-tag type="success" v-if="scope.row.sms_send_status === '发送完成'">{{scope.row.sms_send_status}}</el-tag>
            <el-tag type="danger" v-if="scope.row.sms_send_status === '发送异常'">{{scope.row.sms_send_status}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sending_count" label="接收中/条"  align="center"></el-table-column>
        <el-table-column prop="success_count" label="接收成功/条"  align="center"></el-table-column>
        <el-table-column prop="failures_count" label="接收失败/条"  align="center"></el-table-column>
        <el-table-column prop="scheduler_status"  label="定时任务状态"  align="center">
          <template slot-scope="scope" >
            <el-tag v-if="scope.row.execution_time" :type="scope.row.scheduler_status === '运行中' ? 'primary' :(scope.row.scheduler_status === '已删除' ? 'danger' : 'warning' )" disable-transitions>
              {{scope.row.scheduler_status}}</el-tag>
               <span v-else>{{'--'}} </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" min-width="130">
          <template slot-scope="scope">
            <el-button size="small" type="text"   icon="el-icon-remove-outline" @click="pauseJob(scope.row)" v-if="scope.row.scheduler_status==='运行中'" dark>暂停</el-button>
            <el-button size="mini"  type="text" icon="el-icon-circle-check" @click="resumeJob(scope.row)" v-if="scope.row.scheduler_status==='已暂停'"  dark>启动</el-button>
            <el-button size="mini"  type="text" icon="el-icon-delete"  @click="delJob(scope.row)"   v-if="scope.row.scheduler_status==='已暂停'"   dark >删除</el-button>
            <el-button size="mini"  type="text" icon="el-icon-delete" @click="delJob(scope.row)"   v-if="scope.row.scheduler_status==='运行中'" dark >删除</el-button>
          </template>
        </el-table-column>
    </el-table>
    <div>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="recordsForm.current_page"
        :limit.sync="recordsForm.page_size"
        @pagination="getSMSsending"
      />
    </div>
    </el-form>
  </div>
</template>
<script>
import {errorAlert,successAlert}from "../../../utils/alert"
import { formatTime } from "@/utils/formDate.js"
export default {
  data() {
    return {
      pictLoading:false,
      total:0,
       recordsForm:{
          current_page: 1,
          page_size: 10,
          sms_theme:'',
          sms_send_status:''
      },

      SMSbalance: 0, 
      records: [],
      loading: false,
    }
  },

  created() {
    this.getSMSsending();
    this.getSMSbalance();
  },

  methods: {
   
     delJob(row){
           this.$confirm('此操作将永久删除该任务, 是否继续?', '提示', {
              confirmButtonText: '确定',
               cancelButtonText: '取消',
                type: 'danger'
           }).then(()=>{
                this.delete(row)
           row.scheduler_status="已删除"
           }).catch(()=>{
            this.$message({
            type: 'info',
            message: '已取消删除'
          }); 
           })
      }, 
      
    async delete(data){
      const res =  await this.$http.post("/periodic_task/scheduler_job/delete",{job_id:data.scheduler_job_id} )
        if(res.data.code ==0){
            successAlert(res.data.msg)
            this.getSMSsending();
          }else{
            errorAlert(res.data.msg)
          }
    },
      
    pauseJob(row){
      this.$confirm('确定暂停该任务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          this.stop(row,)
          }).catch(()=>{
            this.$message({
            type: 'info',
            message: '已取消暂停'
          }); 
        }) 
    },
   
    async stop(data){
      const res =  await this.$http.post("/periodic_task/scheduler_job/stop",{job_id:data.scheduler_job_id} )
      if(res.data.code ==0){
        successAlert(res.data.msg)
        this.getSMSsending();
      }else{
        errorAlert(res.data.msg)
      }
    },
   
     resumeJob(row){
        let time = formatTime().timers
        let execution=row.execution_time
        let times=time.replace(/-/g,"/");
        let executions=execution.replace(/-/g,"/");
       if(Date.parse(times)>Date.parse(executions)){
          return this.$confirm('由于您创建的定时任务超时，该任务已失效，请手动删除','提示',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(()=>{})
              }else{
                this.$confirm('确定恢复该任务吗?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then(()=>{ 
                this.start(row)
            }).catch(()=>{
                this.$message({
                  type: 'info',
                  message: '已取消启动'
              }); 
            })
             }
    },
     
    async start(data){
      const res =  await this.$http.post("/periodic_task/scheduler_job/restore",{job_id:data.scheduler_job_id} )
        if(res.data.code ==0){
          successAlert(res.data.msg)
          this.getSMSsending();
        }else{
          errorAlert(res.data.msg)
        }
      },

     headClass() {
      return 'background: #f5f5f7;border-bottom:none;color:rgba(0,0,0,.85)'
      },

    creatTask(row) {
      window.openModal(
      () => import("./create-task.vue"),
      { row },
      { success: ()=>{
        setTimeout(()=>{
          this.getSMSsending();
        },1000)
      }}
    );
    },
    
    async getSMSsending() { 
      this.pictLoading = true
      let params = {
        ...this.recordsForm
      };
      const res = await this.$http.get("/message_task_records/get", params);
      this.pictLoading = false
      if (res.data.code !==0) {return errorAlert(res.data.msg)}
        this.records=res.data.data.records;
        this.total=res.data.data.total
    },
    
    async getSMSbalance() {
      const res = await this.$http.get("/message_balance/get");
      this.SMSbalance = res.data.data;
    },
     
    submitSearch(){
      this.recordsForm.current_page=1
      this.getSMSsending();
    },
      
    resetForm(formName) {
      this.$refs[formName].resetFields();
        this.submitSearch();
    },
    
  },
};
</script>

<style lang="scss" scoped>

.el-pagination {
  background-color: #fff;
  width: 100%;
}
.create_button {
  position: absolute;
  right: 44px;
  top: 94px;
}
.balance{
 position: absolute;
    top: 84px;
    right: 180px;
    font-size: 16px;

}
.themeCss[data-v-3f7d9ff0] {
    padding-top: 20px;
    margin: 0 30px 0 30px;
}
/deep/.el-table .cell {
    white-space: pre-wrap !important;
}

</style>